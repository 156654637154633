import { CiLocationOn } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { MdPhone } from "react-icons/md";
import { FaRegUser, FaMoneyBillWave } from "react-icons/fa";

export function iconRenderMapper(iconName: string) {
  if (iconName) {
    switch (iconName) {
      case "location":
        return <CiLocationOn />;
      case "email":
        return <AiOutlineMail />;
      case "phone":
        return <MdPhone />;
      case "user":
        return <FaRegUser />;
      case "money":
        return <FaMoneyBillWave />;
      default:
        break;
    }
  }
}

export function iconNameMapper(inputName: string) {
  if (inputName) {
    switch (inputName.toLowerCase()) {
      case "located":
      case "location":
      case "locations":
      case "address":
        return "location";
      case "email":
      case "e-mail":
        return "email";
      case "phone":
      case "phone number":
      case "phone #":
        return "phone";
      case "owner":
      case "operator":
      case "owner / operator":
      case "owner/operator":
        return "user";
      case "payment":
      case "payments":
      case "pay":
      case "receivable payments":
        return "money";
      default:
        break;
    }
  }
  return null;
}
