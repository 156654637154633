import { gql } from "@apollo/client";

const accountPath = "account";
const webpagePath = "webpage";

export const GET_ACCOUNT_QUERY = gql`
  query Account {
    account @rest(type: "Account", path: "${accountPath}/") {
      accountId,
      email,
    }
  }
`;

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation AccountCreate($input: any!) {
    account: create(input: $input)
      @rest(
        type: "Account"
        path: "${accountPath}/"
        method: "POST"
      ) {
      accountId,
      email,
      companyName,
      companyDescription,
      ownerFullName,
    }
  }
`;

export const GET_WEBPAGE_QUERY = gql`
  query Webpage($version: any!) {
    webpage(version: $version) 
    @rest(type: "Webpage", path: "${webpagePath}?{args}") {
      accountId,
      webpageVersion,
      headerTitle,
      companyName,
      companyDescription,
      aboutMeTitle,
      aboutMe,
      logo,
      serviceItemsTitle,
      serviceItems,
      otherTitle,
      otherItems,
      email,
      phone,
      socialUrl,
      owner,
      addressFreeForm,
      otherImg,
      backgroundImg,
      accentColor,
    }
  }
`;

export const CREATE_WEBPAGE_MUTATION = gql`
  mutation WebpageCreate($input: any!) {
    webpage: create(input: $input)
      @rest(
        type: "Webpage"
        path: "${webpagePath}/"
        method: "POST"
      ) {
      accountId,
      webpageVersion,
      headerTitle,
      companyName,
      companyDescription,
      aboutMeTitle,
      aboutMe,
      logo,
      serviceItemsTitle,
      serviceItems,
      otherTitle,
      otherItems,
      email,
      phone,
      socialUrl,
      owner,
      addressFreeForm,
      otherImg,
      backgroundImg,
      accentColor,
    }
  }
`;
