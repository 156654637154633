import { useForm, useFieldArray } from "react-hook-form";
import { CiCircleInfo } from "react-icons/ci";
import { iconRenderMapper } from "../../util/iconMapper";

import "./EasyForm.css";
import useLocalStorage from "../../hooks/useLocalStorage";
import PreviewWrapper from "../Preview/PreviewWrapper";

async function formatData(inputData: {
  companyName: any;
  headerTitle: any;
  backgroundImg: string | any[] | null;
  logo: string | any[] | null;
  serviceItemsTitle: string;
  serviceItems: any[];
}) {
  // TODO: Add data mapper for better data safety
  if (!inputData) {
    return null;
  }

  if (inputData.companyName) {
    // Update headerTitle with company name (topTitle)
    inputData.headerTitle = inputData.companyName;
  }

  if (inputData.backgroundImg && inputData.backgroundImg?.length > 0) {
    inputData.backgroundImg = `${URL.createObjectURL(inputData.backgroundImg[0])}`;
  } else {
    inputData.backgroundImg = null;
  }

  if (inputData.logo && inputData.logo?.length > 0) {
    inputData.logo = `${URL.createObjectURL(inputData.logo[0])}`;
  } else {
    inputData.logo = null;
  }

  // Default title
  inputData.serviceItemsTitle = "Services";
  if (inputData.serviceItems?.length > 0) {
    // If first service is empty just reset with empty array
    if (
      !inputData.serviceItems[0].title &&
      !inputData.serviceItems[0].description
    ) {
      inputData.serviceItems = [];
    } else {
      await Promise.all(
        inputData?.serviceItems.map(async (element, index) => {
          inputData.serviceItems[index].index = index;
          if (element?.img.length > 0) {
            inputData.serviceItems[index].img =
              `${URL.createObjectURL(element.img[0])}`;
          }
        }),
      );
    }
  }

  // Default title
  // inputData.otherTitle = "More Info";

  return inputData;
}

export default function EasyForm() {
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      serviceItems: [{ title: "", description: "", img: "" }],
    },
  });
  const [formData, setFormData] = useLocalStorage("formData", undefined);
  const {
    fields: serviceItems,
    append: appendServiceItem,
    remove: removeLastServiceItem,
  } = useFieldArray({ control, name: "serviceItems" });

  async function onSubmit(data: any) {
    const myData = await formatData(data);
    setFormData(myData);
    // eslint-disable-next-line no-undef
    window.location.href = "#top";
  }

  return formData ? (
    <PreviewWrapper previewData={formData} />
  ) : (
    <div className="easyFormContainer">
      <div className="easyForm">
        <h1>Webpage Generator Form</h1>
        <p>
          <b>Add your business details below</b> and have a professional looking
          webpage generated for you!
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="topSection">
            <p>Start by importing your Google Business profile</p>
            {/* TODO: Import google business profile */}
            <button type="button">Import google business profile</button>
            <h3>Company Info</h3>
            <p className="label">Company Name</p>
            <input
              className="input"
              required
              {...register(
                // @ts-ignore
                "companyName",
              )}
            />

            <p className="label">Company Description / Tagline</p>
            <textarea
              className="textarea"
              required
              // @ts-ignore
              {...register("companyDescription")}
            />

            <p className="label">Company Logo</p>
            <input
              className="image-input"
              type="file"
              {...register(
                // @ts-ignore
                "logo",
              )}
            />

            <p className="label">
              {iconRenderMapper("location")} Located / Address
            </p>
            <input
              className="input"
              {...register(
                // @ts-ignore
                "addressFreeForm",
              )}
            />

            <p className="label">{iconRenderMapper("phone")} Phone</p>
            <input
              className="input"
              type="tel"
              {...register(
                // @ts-ignore
                "phone",
              )}
            />

            <p className="label">{iconRenderMapper("email")} Email</p>
            <input
              className="input"
              type="email"
              {...register(
                // @ts-ignore
                "email",
              )}
            />

            <p className="label">{iconRenderMapper("user")} Owner / Operator</p>
            <input
              className="input"
              {...register(
                // @ts-ignore
                "owner",
              )}
            />
          </div>

          <div className="midSection">
            <span className="formTitle">
              <h3>Services</h3>
              <div className="tooltip">
                <CiCircleInfo />
                <span className="tooltiptext">
                  Add your company services here
                </span>
              </div>
            </span>
            {serviceItems.map((item, index) => (
              <div className="formServiceItem" key={item.id}>
                <p className="label">Title</p>
                <input
                  className="input"
                  {...register(`serviceItems.${index}.title`)}
                />

                <p className="label">Description</p>
                <textarea
                  className="textarea"
                  {...register(`serviceItems.${index}.description`)}
                />

                <p className="label">Image</p>
                <input
                  className="image-input"
                  type="file"
                  {...register(`serviceItems.${index}.img`)}
                />
              </div>
            ))}
            <button
              type="button"
              onClick={() =>
                appendServiceItem({ title: "", description: "", img: "" })
              }
            >
              Add another Service
            </button>
            <button
              type="button"
              onClick={() => removeLastServiceItem(serviceItems.length - 1)}
            >
              Remove this Service
            </button>
          </div>

          <div className="advancedSection">
            <h3>Design Options</h3>
            <p className="label">Background Image</p>
            <input
              className="image-input"
              type="file"
              // @ts-ignore
              {...register("backgroundImg")}
            />

            <p className="label">Accent Color</p>
            <input
              className="input"
              type="color"
              defaultValue="#F7F7F7"
              // @ts-ignore
              {...register("accentColor")}
            />
          </div>

          <button type="submit" className="homeButton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
