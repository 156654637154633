import "./Dashboard.css";

export default function Dashboard() {
  return (
    <div className="accountContainer">
      <h1>Dashboard</h1>
      <p>Check on webpage status.</p>
      <p>Webpage views.</p>
      <p>Webpage published version.</p>
      <p>Previews webpage versions.</p>
    </div>
  );
}
