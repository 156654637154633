import {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
  JSX,
} from "react";
import { Navigate } from "react-router-dom";
import { createClient, Session } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

interface AuthProviderType {
  session: Session | null;
  login: () => JSX.Element;
  logout: () => JSX.Element;
}

const AuthContext = createContext<AuthProviderType>({
  session: null,
  login: () => <></>,
  logout: () => <></>,
});
// TODO: Inject as local vars
const projUrl = "https://lpydbkeaswhgwpfxurpw.supabase.co";
const publicKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxweWRia2Vhc3doZ3dwZnh1cnB3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjU5MDM4MDAsImV4cCI6MjA0MTQ3OTgwMH0.20QHDJzuA_SwEPbvNVRhrP8AiWyXF41s1jjQnj0PPVQ";
const supabase = createClient(projUrl, publicKey);

export default function AuthProvider({ children }: PropsWithChildren) {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const login = () => {
    if (!session) {
      // TODO: Add social login providers
      // TODO: Enable links for forgot password and signup
      // TODO: Need to set up SMTP service for emailing
      return (
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          showLinks={false}
        />
      );
    }
    return <Navigate to="/home/account" />;
  };

  const logout = () => {
    if (session) {
      supabase.auth.signOut();
    }
    return <Navigate to="/" />;
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
