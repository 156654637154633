import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiClose } from "react-icons/tfi";

import "./Home.css";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../auth/AuthProvider";
import { CREATE_ACCOUNT_MUTATION } from "../../api/Requests";

interface HomeMenuProps {
  openMenu: boolean;
  toggleOpenMenu: (onlyMobile?: boolean) => void;
}

export default function Home() {
  const ref = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(true);
  const [width, setWidth] = useState(0);
  const { session } = useAuth();

  // TODO: Change this to be query driven and just update the GETTER to create account if needed...
  const [accountCreate, { data, loading, error }] = useMutation(
    CREATE_ACCOUNT_MUTATION,
  );

  useEffect(() => {
    if (session) {
      accountCreate({
        variables: {
          input: {
            companyName: "Example Company",
            ownerFullName: "Paul Peters",
          },
        },
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth);
    }
  }, []);

  function toggleOpenMenu(onlyMobile = false) {
    if (onlyMobile) {
      if (width < 600) {
        setOpenMenu(val => !val);
      }
    } else {
      setOpenMenu(val => !val);
    }
  }
  return (
    <div className="home" ref={ref}>
      {session && (
        <HomeMenu openMenu={openMenu} toggleOpenMenu={toggleOpenMenu} />
      )}
      {session && (
        <div className="homeOutlet">
          <Outlet context={data} />
        </div>
      )}
      {!session && (
        <div>
          You are not authorized to see this page please{" "}
          <Link to="/login">Login</Link>
        </div>
      )}
    </div>
  );
}

function HomeMenu({ openMenu, toggleOpenMenu }: HomeMenuProps) {
  return (
    <>
      <button
        type="button"
        className="sideBarMobileButton"
        onClick={() => toggleOpenMenu()}
      >
        {openMenu ? <TfiClose /> : <RxHamburgerMenu />}
      </button>
      <div className="sideBar" style={{ display: openMenu ? "block" : "none" }}>
        <div className="sideBarTitle">
          <p>Your Webpage Status:</p>
          <Link to="/home/">unpublished</Link>
        </div>
        <div className="sideBarDash" />
        <div className="sideBarOptions">
          <Link to="/home/" onClick={() => toggleOpenMenu(true)}>
            Dashboard
          </Link>
          <Link to="/home/account" onClick={() => toggleOpenMenu(true)}>
            Account
          </Link>
          <Link to="/home/example#top" onClick={() => toggleOpenMenu(true)}>
            Example Mode
          </Link>
          <Link to="/home/webpage#top" onClick={() => toggleOpenMenu(true)}>
            Edit Webpage
          </Link>
          <Link to="/home/form#top" onClick={() => toggleOpenMenu(true)}>
            Form Mode
          </Link>
        </div>
      </div>
    </>
  );
}
