import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";

const prodApi = "https://yfvqgzce53.execute-api.us-east-1.amazonaws.com/prod/";
const apiKey = "2CDMg9ynok2MPSMYwwMoq4MxR6FPHN5y8IDyKPAU";

const tokenCookie = "sb-lpydbkeaswhgwpfxurpw-auth-token";

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const authCookie = window.localStorage.getItem(tokenCookie);
    const token = authCookie ? JSON.parse(authCookie).access_token : undefined;
    return {
      headers: {
        ...headers,
        Accept: "application/json",
        Authorization: token,
        "x-api-key": apiKey,
      },
    };
  });

  return forward(operation).map(result => result);
});

const restLink = new RestLink({
  uri: prodApi,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authRestLink, restLink]),
});

export default client;
